<script setup lang="ts">
import useConfig from '../composables/useConfig.ts';
import { Day } from '../src/calendar/helpers.ts';
import { loadCalendar } from '../src/data.ts';

const props = withDefaults(defineProps<{
    day?: Day;
    link?: boolean;
}>(), {
    day: () => Day.today(),
});

const config = useConfig();

const calendar = await loadCalendar();
const events = calendar.getCurrentYear()!.eventsByDate[props.day.toString()];
</script>

<template>
    <section
        v-if="config.calendar && config.calendar.enabled && events !== undefined"
        class="alert alert-light border"
    >
        <div class="d-flex flex-column-reverse flex-md-row justify-content-between">
            <p class="h3">
                <Icon v="calendar-star" />
                <T>calendar.banner</T><T>quotation.colon</T>
            </p>
            <p class="small">
                <DateWords :day="day" inline />
            </p>
        </div>
        <ul class="list-unstyled my-3 ms-3">
            <li v-for="event in events" class="mb-2">
                <CalendarEvent :key="event.name" :event="event" />
            </li>
        </ul>
        <nuxt-link v-if="link" :to="{ name: 'calendar' }" class="small">
            <Icon v="angle-right" />
            <T>calendar.headerLong</T>
        </nuxt-link>
    </section>
</template>
